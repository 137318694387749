@import 'src/styles';

.rate__item {
  width: 60px;
  height: 60px;
  background: $questionTitleBgColor;
  box-shadow: $questionAnswerItemBoxShadow;
  border-radius: 15px;
  letter-spacing: -0.36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;

  &.active {
    border-color: $questionActiveTitleBorderColor;
  }

  &.dark {
    &.active {
      background-color: transparent;
    }
  }
}