@import 'core/styles/_normalize';
@import 'core/styles/_global';

@import 'src/styles/fonts';
@import 'src/styles';

* {
  overscroll-behavior: none;
}

html,
body {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100svh;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  font-family: $fontFamily;
  color: $primaryText;
  background: #1F2B31;
}