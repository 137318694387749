@import 'src/styles';

.pageContainer {
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
  overflow-x: hidden;
  pointer-events: none;

  &.isOpen {
    pointer-events: all;
  }

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;

    a {
      font-size: $fontSize17;
      margin-bottom: 30px;
    }
  }
}