@import 'src/styles';

.note {
  width: 100%;
  padding: 10px;
  border-radius: 15px;
  margin-top: 20px;
  background-color: $noteBlockBgColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 345px;

  .icon {
    font-size: $fontSize22;
    margin-bottom: 5px;
  }

  .text {
    font-size: $fontSize14;
    font-weight: $fwn;
    text-align: center;
  }
}