:root {
  --primaryColor: #CC933D;
  --primaryColorLight: #14d5c275;
  --secondaryColor: #16786c;
  --secondaryColorLight: #16786c75;

  --primaryText: #1D2526;
  --notActivePriceText: #1D252699;
  --primaryTextLight: #2d2d2d40;

  --quizFontFamilyTitle: 'Philosopher', sans-serif;
  --quizFontFamily: 'Open Sans', sans-serif;

  --quizBg: #1F2B31;
  --quizGenderCardsBg: #424B51;

  --secondaryText: #B2966B;

  --mainBg: #dbd7d2;
  --secondaryBg: #8251081a;
  --blockBg: #FAF8F5;

  --borderColor: #DBD7D2;

  /* Other */
  --titleText: #6a52ff;
  --questionHighlightTitleColor: var(--primaryColor);
  --answerTitleTextAlign: center;
  --feedbackStarColor: var(--primaryColor);
  --titleH1FontFamily: var(--quizFontFamilyTitle);
  --titleH2Color: var(--primaryText);
  --titleH1Color: #D0B894;
  --subtitleColor: #D0B89499;
  --buttonBackground: #E5B673;
  --linkColor: #E5B673;

  /* SIZES */
  --padding-XXXS: 4px;
  --padding-XXS: 8px;
  --padding-XS: 12px;
  --padding-S: 16px;
  --padding-M: 20px;
  --padding-L: 24px;
  --padding-XL: 36px;
  --padding-XXL: 48px;
  --h1-size: 24px;
  --h2-size: 20px;
  --h3-size: 16px;
  --h4-size: 14px;
  --h5-size: 12px;
  --h1-lh: 36px;
  --h2-lh: 24px;
  --h3-lh: 24px;
  --h3-blod-lh: 20px;
  --h4-lh: 20px;
  --h5-lh: 16px;
  --p1-size: 16px;
  --p2-size: 14px;
  --p3-size: 12px;
  --p4-size: 11px;
  --p1-lh: 24px;
  --p2-lh: 20px;
  --p3-lh: 16px;
  --p4-lh: 16px;
}

@media (min-height: 700px) or (min-width: 400px) {
  :root {
    /* SIZES */
    --padding-XXXS: 4px;
    --padding-XXS: 8px;
    --padding-XS: 12px;
    --padding-S: 20px;
    --padding-L: 28px;
    --padding-M: 28px;
    --padding-XL: 44px;
    --padding-XXL: 52px;
    --h1-lh: 40px;
    --h2-lh: 28px;
    --h3-lh: 24px;
    --h3-blod-lh: 20px;
    --h4-lh: 24px;
    --h5-lh: 20px;
    --h1-size: 28px;
    --h2-size: 24px;
    --h3-size: 16px;
    --h4-size: 16px;
    --h5-size: 14px;
    --p1-size: 20px;
    --p2-size: 14px;
    --p3-size: 14px;
    --p4-size: 12px;
    --p1-lh: 28px;
    --p2-lh: 20px;
    --p3-lh: 20px;
    --p4-lh: 20px;
  }
}