@import 'src/styles';

.question {
  @include question-main-wrap-style;
  max-width: $maxWidthDefault;
  margin: 0 auto;

  .selections_hint {
    font-style: $selectionsHintFontStyle;
    font-family: var(--textFontFamily, $fontFamily);
    font-weight: 400;
    color: $selectionsHintColor;
    font-size: $questionTextFontSize;
    line-height: 1.37;
    opacity: $selectionsHintOpacity;
    margin-top: 15px;

    @media (max-height: 640px) {
      font-size: $questionTextAdaptiveFontSize;
    }
  }

  .subtitle {
    margin-bottom: 30px;
    width: 80%;
  }

  .wrap {
    @include question-wrap-style;
  }

  .title {
    @include question-title-style;

    @media (max-height: 640px) {
      font-size: $questionTitleAdaptiveFontSize;
    }

    b {
      color: var(--questionHighlightTitleColor);
    }
  }

  .fade-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    max-width: $maxWidthDefault;

    &__color-content {
      width: 100%;
    }
  }

  .answers-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: $maxWidthDefault;
    margin: 0 auto 20vh;

    &_mini {
      flex-wrap: wrap;
      max-width: 100%;
    }

    &_rectangle {
      width: 100%;
      align-items: center;
      flex-direction: column;
    }

    &_square {
      flex-wrap: wrap;
      justify-content: space-evenly;
      max-width: $maxWidthDefault;
    }

    &_oval {
      flex-wrap: wrap;
      max-width: $maxWidthDefault;
    }
  }

  .selected-items {
    @include question-selected-items-style;

    @include map-styles($questionSelectedItemsStyles);

    .count {
      margin-left: 2px;

      @include map-styles($questionSelectedItemsCountStyles);
    }
  }

  .item {
    @include question-answers-item-style;
  }

  .button_wrap {
    position: fixed;
    bottom: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &.dark {
    .title {
      @include title-h1;
      text-align: var(--answerTitleTextAlign);
      padding-bottom: 20px;
    }

    .selected-items {
      color: $textColor;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;

      .count {
        color: $textColor;
        font-weight: $fwstb;
      }
    }
  }
}