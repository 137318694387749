@import 'src/styles';

.bipolar__item {
  width: 47%;
  height: 110px;
  background: $questionTitleBgColor;
  box-shadow: $questionAnswerItemBoxShadow;
  border-radius: 15px;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: $fwb;
  font-size: $fontSize14;
  line-height: $fontSize20;
  text-align: center;
  letter-spacing: -0.36px;
  color: $questionTitleColor;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px solid transparent;

  .title {
    padding-top: 10px;
  }

  &.active {
    border-color: $questionActiveTitleBorderColor;
    color: $questionActiveTitleColor;
  }

  &.dark {
    font-weight: $fwstb;
    font-family: $fontFamilyPhilosopher;
    font-size: $fontSize17;

    &.active {
      background-color: transparent;
    }
  }
}