@import 'src/styles';

.square {
  width: 45%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $questionTitleBgColor;
  border-radius: $questionAnswerBorderRadius;
  margin-bottom: 10px;
  box-shadow: $boxShadow;
  border: 2px solid $questionAnswerBorderColor;

  &.caption_view {
    height: 160px;
    background-size: cover;
    border: none;
    position: relative;

    .title_block {
      position: absolute;
      bottom: -1px;
      display: flex;
      align-items: center;
      height: 45px;
      width: 100%;
      border-radius: 0 0 $questionAnswerBorderRadius $questionAnswerBorderRadius;
      background-color: $white;
    }

    .border {
      border: 2px solid transparent;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: $questionAnswerBorderRadius;
    }
  }

  &.bg_view {
    height: 140px;
    background-size: cover;
    background-repeat: no-repeat;
    border: 2px solid var(--questionBorderColor, white);

    .button {
      justify-content: center !important;

      .title {
        color: var(--questionTitleImageColor, $questionTitleImageColor);
      }
    }

  }

  .button {
    padding: 15px 0;
    width: 100%;
    height: 100%;
    transition: none;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      margin-bottom: 10px;
    }
  }

  &.active {
    border-color: $questionActiveTitleBorderColor;
    background-color: $questionActiveTitleBgColor;

    .border {
      border-color: $questionActiveTitleBorderColor;
    }

    .title {
      color: $questionActiveTitleColor;
      font-weight: $questionActiveFontWeight;
    }

    .title_block {
      background-color: $questionActiveTitleBgColor;
    }
  }

  .icon {
    margin-bottom: 5px;
  }

  .color {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    margin-bottom: 10px;
  }

  .title {
    @include text-style;
    font-size: $questionTextFontSize;
    font-weight: $questionTitleFontWeight;
    letter-spacing: rem(-0.36);
    color: $questionTitleColor;
    margin: 0 auto;
    // padding-top: 5px;
    white-space: break-spaces;

    @media (max-height: 640px) {
      font-size: $questionTextAdaptiveFontSize;
    }
  }

  &.three_column {
    width: 30%;
    height: 130px;
  }

  &.bg_view_three_column {
    height: 112px;
  }

  &.duo {
    padding: 30px 3px;
  }

  &.dark {
    .title {
      font-family: $fontFamilyPhilosopher;
      font-weight: $fwstb;
      font-size: $fontSize18;
      line-height: $fontSize20;
    }

    .subtitle {
      color: $titleH3Color;
      font-weight: $fwsb;
      font-size: $fontSize12;
      line-height: $fontSize20;
    }

    &.active {
      background: transparent;
    }
  }
}