@import "src/styles";

@keyframes loader {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: $primaryColor;

  svg {
    width: 50px;
    height: 50px;
    animation: loader 1s linear infinite;
  }
}