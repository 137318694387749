@import 'src/styles';

.oval {
  display: flex;
  justify-content: center;
  position: relative;
  max-width: $maxWidthDefault;

  &.active {
    .button {
      border-color: $questionActiveTitleBorderColor;
    }

    .description {
      .title {
        color: $questionActiveTitleColor;
        font-weight: $fwb;
      }
    }
  }

  .button {
    transition: none;
    display: flex;
    border-radius: 30px;
    align-items: center;
    background-color: $questionTitleBgColor;
    margin-bottom: 10px;
    margin-right: 5px;
    border: 2px solid transparent;
    box-shadow: $boxShadow;
    padding: 15px 18px;
  }

  .description {
    text-align: left;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .title {
      font-family: $fontFamily;
      font-size: $fontSize16;
      font-weight: $questionTitleFontWeight;
      letter-spacing: rem(-0.46);
      color: $questionTitleColor;
      padding-left: 8px;
    }
  }


  &.dark {
    .button {
      border: 1.5px solid $questionAnswerBorderColor;
      margin-bottom: 10px;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
      border-radius: 12px;
      min-height: 50px;
    }

    .title {
      font-family: $fontFamilyPhilosopher;
    }

    &.active {
      .button {
        background-color: var(--questionActiveTitleBackgroundColor, transparent);
        border-color: $questionActiveTitleBorderColor;
      }
    }
  }

  .image {
    height: 34px;
    width: 34px;
  }

  .title {
    font-family: $fontFamilyPhilosopher;
    color: $questionTitleColor;
  }
}