@import "src/styles";


.progressBarWrapper {
  height: 60px;
  min-height: initial;
}

.logo {
  font-size: $fontSize20;
  font-weight: $fwstb;
  font-family: $fontFamilyPhilosopher;
  color: #DBD7D2;
  text-align: center;
}