@import "src/styles/index";

.footer {
  max-width: $maxWidthDefault;
  font-size: $fontSize14;
  line-height: $fontSize30;
  color: var(--borderColor);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  padding: 10px 0;
  margin: 10px auto 0;

  &__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
  }

  &__link {
    text-decoration: underline;
    color: var(--borderColor);
    flex-basis: 40%;
    text-align: center;
  }

  &__divider {
    display: flex;
    width: 2px;
    height: 25px;
    background-color: var(--borderColor);
    margin: 0 8px;
  }

  &__allRightsReserved {
    overflow-wrap: break-word;
    width: 80%;
    margin: auto;
    text-align: center;
  }
}