@import 'src/styles';


.mini-rectangle {
  padding: 6px;

  &.active {
    .button {
      background-color: $questionActiveTitleBgColor;
      border: 2px solid $questionActiveTitleBorderColor;
    }

    .title {
      color: $questionActiveTitleColor;
    }
  }

  .description {
    display: flex;
    justify-content: center;
    width: max-content;

    &.withIcon {
      padding-left: 2px;
    }
  }

  .button {
    display: flex;
    border-radius: 10px;
    align-items: center;
    background-color: $questionTitleBgColor;
    border: 2px solid transparent;
    box-shadow: $boxShadow;
    height: 40px;
    padding: 12px 10px;
  }

  .title {
    font-weight: $questionMiniRectangleAnswerFontWeight;
    font-size: $fontSize14;
    letter-spacing: rem(-0.026);
    color: $questionTitleColor;
    padding: 0 3px;

    @include map-styles($questionAnswerTitleStyles)
  }

  &.dark {
    &.active {
      .button {
        background-color: transparent;
      }
    }

    .button {
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
      padding: 15px 10px;
    }

    .title {
      font-size: $fontSize16;
      line-height: $fontSize16;
      font-family: $fontFamilyPhilosopher;
    }

    &:disabled {
      .button {
        background-color: $questionButtonBgColor;
      }

      .title {
        color: $questionTitleBgColor;
      }
    }
  }
}