@import "core/styles";
@import 'src/styles/mixin';
@import 'src/styles/mixins';

$fontFamily: Poppins, sans-serif;
$fontFamilyPhilosopher: Philosopher, sans-serif;

$primaryText: var(--primaryText);

$fieldText: #1D2526;
$fieldBg: #FFF;
$fieldBorderColor: #DEE2E6;

$boxShadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.20);

$errorColor: #D35900;

$shippingBg: #FAF8F5;

$transition: all ease 300ms;

// buttons
$buttonBackground: #E5B673;
$buttonFontSize: $fontSize22;
$buttonFontFamily: $fontFamilyPhilosopher;
$buttonTitleColor: #1D2526;
$buttonTitleShadow: none;

// links
$preloaderColor: $primaryColor;

//magic
$magicTextsColor: var(--primaryText);

// titles
$titleH1FontSize: $fontSize24;
$titleH1FontWeight: $fwstb;
$titleH1FontFamily: $fontFamily;
$titleH1Color: var(--titleH1Color);

$titleH2Color: var(--titleH2Color);
$titleH2FontSize: $fontSize22;

$titleH4Color: var(--primaryText, $primaryColor);
$titleH4FontSize: $fontSize14;
$titleH4FontFamily: $fontFamily;

//autocomplete
$autocompleteBgColor: $white;
$autocompleteTextColor: $fieldText;
$autocompleteBorderColor: $fieldText;

$autocompleteEmailBgColor: $white;
$autocompleteEmailBorderColor: $fieldText;
$autocompleteEmailTextColor: $fieldText;

$errorModalBgColor: #09968f;
$errorModalCloseBtnBgColor: var(--buttonBackground);
$errorModalSkipBtnColor: $white;
$errorModalFontFamily: $fontFamilyPhilosopher;
$errorModalTitleColor: #f2e6c4;

// palm-reading
$palmReadingScanAnimBg: linear-gradient(180deg, rgba(229, 182, 115, 0.00) 0%, #E5B673 100%);
$backgroundBlockColor: #1D2526;
$palmReadingPhotoOuterBgColor: $white;
$palmReadingPhotoInnerBgColor: #E5B673;
$palmReadingTextColor: #FAF8F5;
$palmReadingAnalyzingTextColor: $palmReadingTextColor;
$accentTextColor: #D0B894;
$palmReadingDisclaimerColor: rgba(208, 184, 148, 0.60);
$palmReadingPrivacyTextColor: rgba(208, 184, 148, 0.60);

$palmReadingWelcomeTitleStyles: (
  'color': var(--titleH1Color),
  'font-size': $fontSize24,
  'font-family': $fontFamilyPhilosopher,
  'line-height': 120%,
);
$palmReadingWelcomeSubtitleStyles: (
);
$palmReadingWelcomeButtonTitleStyles: (
  'color': var(--primaryText),
  'font-size': $fontSize22,
  'font-family': $fontFamilyPhilosopher,
  'text-shadow': none,
);
$palmReadingProgressTextStyles: (
  'color': $palmReadingTextColor,
  'font-size': $fontSize30,
  'font-family': $fontFamilyPhilosopher,
);
$palmReadingBottomBgStyles: (
);
$palmReadingBackButtonStyles: (
);

$palmReadingLinkColor: var(--secondaryText);
$palmReadingButtonBg: #E5B673;

$analyzingPalmReadingBgColor: #1D2526;
$palmReadingProgressPadding: 3px;
$palmReadingProgressBgColor: rgba(255, 255, 255, 0.10);
$palmReadingProgressColor: #E5B673;

//questions
$questionTitleColor: var(--titleH1Color);
$questionTitleFontSize: $fontSize24;
$questionTitleAdaptiveFontSize: $fontSize24;

$questionTitleBgColor: rgba(255, 255, 255, 0.10);
$questionActiveTitleBgColor: #1D2526;
$questionActiveTitleBorderColor: #E5B673;

$questionTextFontSize: $fontSize17;
$questionTextAdaptiveFontSize: $fontSize17;

$questionButtonBgColor: rgba(255, 255, 255, 0.10);

$questionAnswerTitleStyles: (
  font-family: $fontFamilyPhilosopher,
  font-weight: $fwstb,
);
$questionSelectedItemsStyles: (
  font-family: Open Sans,
  font-size: $fontSize16,
  font-weight: $fwn,
  color: rgba(208, 184, 148, 0.60),
);
$questionSelectedItemsCountStyles: (
  font-weight: $fwb,
  color: #DBD7D2
);

//progress bar
$progressBarActiveColor: #E5B673;
$progressBarBgColor: rgba(255, 255, 255, 0.10);
$progressBarCounterStyles: (
  color: #DBD7D2,
  font-weight: $fwb
);

$backArrowButtonColor: #DBD7D2;

//autocomplete
$autocompleteBgColor: #354046;
$autocompleteBorderColor: rgba($white, 0.5);
$autocompleteTextColor: $white;

//sideBar
$sideBarDarkButtonColor: #DBD7D2;
$sideBarDarkBgColor: #1D2526;
$sideBarColor: #FAF8F5;