@import 'src/styles';

.number__item {
  width: 60px;
  height: 60px;
  background: $questionTitleBgColor;
  box-shadow: $questionAnswerItemBoxShadow;
  border-radius: $questionAnswerBorderRadius;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: $questionTitleFontWeight;
  font-size: $questionTextFontSize;
  line-height: 1.37;
  text-align: center;
  letter-spacing: -0.36px;
  color: $questionTitleColor;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;

  @media (max-height: 640px) {
    font-size: $questionTextAdaptiveFontSize;
  }

  &.active {
    border-color: $questionActiveTitleBorderColor;
    color: $questionActiveTitleColor;
    background-color: $questionActiveTitleBgColor;
    font-weight: $questionActiveFontWeight;
  }

  &.dark {
    font-weight: $fwsb;
    font-size: $fontSize18;
    line-height: $fontSize24;

    &.active {
      background-color: transparent;
    }
  }
}