@import 'src/styles';

.question {
  @include question-main-wrap-style;
  max-width: $maxWidthDefault;
  margin: 0 auto;

  .inner-wrap {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .title_wrap {
    position: relative;

    .quotes {
      position: absolute;
      top: 6px;
      left: calc(50% - 42px);

      path {
        fill: $quotesIconColor;
      }
    }

    &.block {
      background-color: var(--secondaryBg);
      width: 100%;
      max-width: 320px;
      height: 33vh;
      border-radius: 15px;
      padding: 20px 10px 0;

      .title {
        font-size: $fontSize20;
        font-weight: $fwb;
        padding: 0;

        @media(max-height: 750px) {
          font-size: $fontSize18;
        }
      }

      .headImageBg {
        position: absolute;
        bottom: -70px;
        left: 50%;
        transform: translate(-50%, -50%);

        @media(max-height: 750px) {
          width: 80%;
          bottom: -45px;
        }
      }
    }
  }

  .headImage {
    margin-bottom: 65px;

    &.top {
      margin-bottom: 0;
      margin-top: 30px;
      width: 95%;
    }
  }

  .uptitle {
    margin: 5vh auto 2vh;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: $fontSize16;
    line-height: $fontSize22;
    text-align: center;
    letter-spacing: -0.6px;
    padding: 0 15px;
    color: $questionUpTitleColor;
  }

  .answers-list {
    @include question-answers-list-style;
    margin-bottom: 0;

    &_mini {
      flex-wrap: wrap;
      padding: 0 20px;
    }

    &_rectangle {
      width: 100%;
      align-items: center;
      flex-direction: column;
    }

    &_square {
      flex-wrap: wrap;
      justify-content: space-evenly;
      max-width: $maxWidthDefault;
      max-height: max-content;
      width: 100%;

      .item {
        &.tall {
          padding: 40px;
        }
      }
    }

    &_bipolar {
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      max-width: 320px;
      position: absolute;
      bottom: 20%;
    }

    &_numeric,
    &_rate {
      flex-wrap: wrap;
      justify-content: space-between;
      flex-direction: row;
      width: 100%;
      max-width: 345px;
      margin-top: 15vh;
    }
  }

  .selected-items {
    @include question-selected-items-style;
  }

  .item {
    @include question-answers-item-style;
  }


  .number_wraper,
  .rate_wraper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: $questionTextAdaptiveFontSize;
    line-height: 1.42;
    color: $mineShaft;
    opacity: 0.4;

    .rate_text {
      font-size: $fontSize12;
    }
  }

  .skip {
    padding: 25px 0;
    margin-top: auto;
  }

  &.dark {
    .title_wrap {
      display: flex;
      justify-content: center;

      .quotes {
        position: absolute;
        top: 18px;
        left: 20px;

        path {
          fill: rgba(61, 116, 129, 0.20);
        }
      }

      .title {
        margin-bottom: 10px;
        width: 100%;
        @include title-h1;
        text-align: var(--answerTitleTextAlign);
      }
    }

    .uptitle {
      color: $textColor;
    }

    .subtitle {
      color: $textColor;
      text-align: var(--answerTitleTextAlign);
    }

    .number_wraper {
      color: $textColor;
      opacity: 0.6;
    }
  }

}

.title {
  @include question-title-style;
  position: relative;
  z-index: 2;
  text-align: var(--answerTitleTextAlign, initial);
  font-family: var(--titleH1FontFamily, $titleH1FontFamily);

  @media (max-height: 640px) {
    font-size: $questionTitleAdaptiveFontSize;
  }

  b {
    color: var(--questionHighlightTitleColor);
  }

  &.with_subtitle {
    padding-bottom: 1vh;
  }
}

.subtitle {
  margin-bottom: 30px;
  width: 80%;
  color: $questionSubTitleColor;
  text-align: var(--answerTitleTextAlign);
  font-family: var(--textFontFamily, $fontFamily);
}