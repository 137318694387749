@import 'src/styles';

.rectangle {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  max-width: 375px;

  .checkmark {
    position: absolute;
    right: 30px;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background: var(--questionCheckmarkBg);
  }

  &.active {
    .button {
      border-color: $questionActiveTitleBorderColor;
      background-color: $questionActiveTitleBgColor;
    }

    .checkmark {
      background: var(--questionActiveCheckmarkColor);
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        stroke: var(--questionCheckmarkIconColor);
        width: 12px;
        height: 9px;
      }
    }

    .description {
      .title {
        color: $questionActiveTitleColor;
        font-weight: $questionActiveFontWeight;
      }
    }

    .iconCheck {
      stroke: $questionIconCheckColor;
    }
  }

  .button {
    transition: none;
    display: flex;
    border-radius: $questionAnswerBorderRadius;
    align-items: center;
    width: 100%;
    min-height: 70px;
    max-width: 345px;
    background-color: $questionTitleBgColor;
    margin-bottom: 10px;
    border: 2px solid transparent;
    box-shadow: $boxShadow;
    padding: $questionRectangleAnswerPadding;
  }

  .description {
    width: 90%;
    text-align: left;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .title {
      line-height: 1.31;
      font-family: var(--textFontFamily, $fontFamily);
      font-size: $questionTextFontSize;
      font-weight: $questionTitleFontWeight;
      letter-spacing: rem(-0.46);
      color: $questionTitleColor;
      padding: 0 15px;
      width: 90%;

      @include map-styles($questionAnswerTitleStyles);

      @media (max-height: 640px) {
        font-size: $questionTextAdaptiveFontSize;
      }
    }

    .subtitle {
      padding: 5px 15px;
    }

    .iconCheck {
      position: absolute;
      right: 30px;
    }
  }


  &.dark {
    .description {
      .title {
        width: 100%;
      }
    }

    &.withCheckmark {
      .description {
        .title {
          width: 90%;
        }
      }
    }

    .button {
      border: 1.5px solid $questionAnswerBorderColor;
      margin-bottom: 10px;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
      border-radius: 12px;
      min-height: 50px;
    }

    .title {
      font-family: var(--fontFamily, $fontFamilyPhilosopher);
    }

    .iconCheck {
      display: none;
    }

    &.active {
      .button {
        background-color: var(--questionActiveTitleBackgroundColor, transparent);
        border-color: $questionActiveTitleBorderColor;
      }
    }
  }

  .image {
    height: 34px;
    width: 34px;
  }

  .color {
    height: 34px;
    width: 34px;
    border-radius: 50%;
  }

  .title {
    font-family: $fontFamilyPhilosopher;
    color: $questionTitleColor;
  }

  .subtitle {
    padding: 5px 15px;
    text-align: left;
    color: $questionAnswerSubtitleColor;
  }
}